<script lang="ts" setup>
import useDarkMode from '@/composables/useDarkMode';
import useTailwind from '@/composables/useTailwind';

/**
 * TYPES
 */
type Props = {
  forceDark?: boolean;
};

/**
 * PROPS & EMITS
 */
const props = withDefaults(defineProps<Props>(), {
  forceDark: false,
});

/**
 * COMPOSABLES
 */
const { darkMode } = useDarkMode();
const tailwind = useTailwind();

/**
 * COMPUTED
 */
const fillColor = computed(() => {
  if (props.forceDark) return tailwind.theme.colors.white;

  return darkMode.value
    ? tailwind.theme.colors.white
    : tailwind.theme.colors.gray['900'];
});
</script>

<template>
  <div class="flex items-center sm:mr-4 app-logo">
    <svg
      id="logo"
      viewBox="0 0 300 34"
      height="25"
      class="logo-svg"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="logoTitle logoDesc"
      role="img"
    >
      <title id="logoTitle">Balancer logo homepage link</title>
      <desc id="logoDesc">Balancer Logotype homepage link</desc>
      <g :fill="fillColor" fill-rule="nonzero" class="svg-path">
        <path
          d="M33.0872 5.62108C33.0872 5.62108 32.8628 5.3405 31.6971 5.3469C30.5314 5.3533 17.5169 5.3469 17.5169 5.3469L20.4363 0.000257252C20.4363 0.000257252 24.9818 -0.000321565 26.8313 0.000257252C28.7843 0.000866533 30.0305 0.620399 31.2799 2.60513C32.2281 4.11082 32.4865 4.55575 33.0872 5.62108Z"
        />
        <path
          d="M15.4317 0.000254711C15.4317 0.000254711 8.82451 11.5113 8.06348 12.8872C7.30246 14.2631 6.80819 14.6521 6.81236 15.9033C6.81653 17.1545 7.07648 19.071 9.87082 19.0565C9.87082 19.0565 23.5933 19.0702 24.8849 19.0565C25.9781 19.0448 26.2921 19.2429 26.2752 19.6048C26.2584 19.9668 25.7739 20.6592 25.1631 21.6613C24.9084 22.079 24.6129 22.5652 24.3289 23.0322C23.9318 23.6854 23.6326 24.2666 22.7997 24.266C22.7997 24.266 7.20747 24.275 5.56123 24.266C3.58353 24.2553 2.81169 23.9067 1.52956 21.6613C0.247438 19.416 0.0160266 19.1875 0.000410746 17.5484C-0.0152052 15.9092 0.41302 15.4966 1.52964 13.5727C2.64626 11.6487 6.39987 5.14827 7.78553 2.87931C9.17119 0.610343 10.7025 -0.000369803 12.6513 0.000254711H15.4317Z"
        />
        <path
          d="M5.56124 28.3799C5.56124 28.3799 5.78551 28.6604 6.95138 28.654C8.11724 28.6476 21.1314 28.654 21.1314 28.654L18.212 33.9997C18.212 33.9997 13.6665 34.0003 11.817 33.9997C9.86418 33.9991 8.618 33.3796 7.36842 31.3954C6.42034 29.8898 6.16193 29.445 5.56124 28.3799Z"
        />
        <path
          d="M23.2168 33.9997C23.2168 33.9997 29.8239 22.4909 30.5848 21.1151C31.3457 19.7393 31.8401 19.3505 31.8361 18.0996C31.8321 16.8488 31.572 14.9326 28.7776 14.947C28.7776 14.947 15.0549 14.9332 13.7634 14.947C12.6704 14.9586 12.3564 14.7604 12.3732 14.3987C12.39 14.0369 12.8746 13.3445 13.4853 12.3427C13.7399 11.925 14.0355 11.4388 14.3194 10.9719C14.7167 10.3188 15.0159 9.73769 15.8487 9.7383C15.8487 9.7383 31.441 9.72931 33.0872 9.7383C35.0649 9.74896 35.8368 10.0976 37.1188 12.3427C38.4008 14.5877 38.6323 14.8159 38.6481 16.4547C38.6638 18.0935 38.2355 18.5062 37.1188 20.4298C36.002 22.3535 32.2487 28.8527 30.8628 31.1213C29.477 33.39 27.9459 34.0003 25.9972 33.9997H23.2168Z"
        />
        <path
          d="M58.5162 13.0567C58.4477 12.3749 58.1534 11.8452 57.6334 11.4677C57.1134 11.0901 56.4077 10.9013 55.5163 10.9013C54.9106 10.9013 54.3992 10.9859 53.9821 11.1549C53.565 11.3183 53.245 11.5465 53.0221 11.8396C52.805 12.1326 52.6964 12.465 52.6964 12.8369C52.685 13.1469 52.7507 13.4173 52.8936 13.6484C53.0421 13.8794 53.245 14.0794 53.5021 14.2485C53.7593 14.4119 54.0564 14.5556 54.3935 14.6796C54.7307 14.7979 55.0906 14.8993 55.4735 14.9838L57.0506 15.3558C57.8163 15.5248 58.5191 15.7502 59.1591 16.0319C59.799 16.3137 60.3533 16.6602 60.8219 17.0716C61.2904 17.4829 61.6533 17.9675 61.9104 18.5254C62.1732 19.0833 62.3075 19.7228 62.3132 20.4441C62.3075 21.5035 62.0333 22.422 61.4904 23.1996C60.9533 23.9716 60.1762 24.5717 59.1591 24.9999C58.1477 25.4226 56.9277 25.6339 55.4992 25.6339C54.0821 25.6339 52.8479 25.4197 51.7965 24.9915C50.7508 24.5632 49.9337 23.9293 49.3451 23.0897C48.7623 22.2445 48.4566 21.1992 48.428 19.9539H52.0193C52.0593 20.5343 52.2279 21.0189 52.525 21.4077C52.8279 21.7908 53.2307 22.081 53.7335 22.2783C54.2421 22.4699 54.8164 22.5657 55.4563 22.5657C56.0849 22.5657 56.6306 22.4755 57.0934 22.2952C57.562 22.1149 57.9248 21.8641 58.182 21.5429C58.4391 21.2217 58.5677 20.8526 58.5677 20.4356C58.5677 20.0468 58.4505 19.72 58.2162 19.4552C57.9877 19.1903 57.6505 18.9649 57.2048 18.779C56.7649 18.593 56.2249 18.424 55.5849 18.2718L53.6735 17.7985C52.1936 17.4435 51.0251 16.8885 50.168 16.1334C49.3108 15.3783 48.8851 14.3612 48.8909 13.0821C48.8851 12.034 49.168 11.1183 49.7394 10.335C50.3165 9.55177 51.1079 8.94038 52.1136 8.50086C53.1193 8.06133 54.2621 7.84157 55.5421 7.84157C56.8449 7.84157 57.982 8.06133 58.9534 8.50086C59.9305 8.94038 60.6904 9.55177 61.2333 10.335C61.7761 11.1183 62.0561 12.0255 62.0733 13.0567H58.5162Z"
        />
        <path
          d="M66.9952 30.2573C66.5324 30.2573 66.0981 30.2207 65.6924 30.1475C65.2924 30.0798 64.961 29.9925 64.6982 29.8854L65.521 27.1976C65.9495 27.3272 66.3352 27.3976 66.6781 27.4089C67.0266 27.4201 67.3266 27.3413 67.5781 27.1722C67.8352 27.0032 68.0438 26.7158 68.2038 26.3101L68.418 25.7607L63.6953 12.4059H67.5352L70.2608 21.9402H70.398L73.1493 12.4059H77.0149L71.8979 26.7919C71.6522 27.4906 71.3179 28.0992 70.8951 28.6176C70.478 29.1416 69.9494 29.5445 69.3094 29.8263C68.6694 30.1136 67.898 30.2573 66.9952 30.2573Z"
        />
        <path
          d="M78.8834 25.3888V12.4059H82.3633V14.6965H82.5175C82.7918 13.9358 83.2489 13.3356 83.8889 12.8961C84.5289 12.4566 85.2946 12.2368 86.186 12.2368C87.0888 12.2368 87.8573 12.4594 88.4916 12.9046C89.1259 13.3441 89.5487 13.9414 89.7601 14.6965H89.8973C90.1658 13.9527 90.6515 13.3582 91.3544 12.913C92.0629 12.4622 92.9 12.2368 93.8657 12.2368C95.0942 12.2368 96.0913 12.6228 96.857 13.3948C97.6284 14.1611 98.0141 15.2487 98.0141 16.6574V25.3888H94.3714V17.3674C94.3714 16.6462 94.1771 16.1052 93.7886 15.7446C93.4 15.3839 92.9143 15.2036 92.3315 15.2036C91.6686 15.2036 91.1515 15.4121 90.7801 15.8291C90.4087 16.2404 90.223 16.7842 90.223 17.4604V25.3888H86.6831V17.2914C86.6831 16.6546 86.4974 16.1475 86.126 15.7699C85.7603 15.3924 85.2774 15.2036 84.6775 15.2036C84.2718 15.2036 83.9061 15.305 83.5804 15.5079C83.2604 15.7051 83.0061 15.9841 82.8175 16.3447C82.629 16.6997 82.5347 17.1167 82.5347 17.5956V25.3888H78.8834Z"
        />
        <path
          d="M100.898 25.3888V12.4059H104.378V14.6965H104.532C104.807 13.9358 105.264 13.3356 105.904 12.8961C106.544 12.4566 107.31 12.2368 108.201 12.2368C109.104 12.2368 109.872 12.4594 110.507 12.9046C111.141 13.3441 111.564 13.9414 111.775 14.6965H111.912C112.181 13.9527 112.666 13.3582 113.369 12.913C114.078 12.4622 114.915 12.2368 115.881 12.2368C117.109 12.2368 118.106 12.6228 118.872 13.3948C119.643 14.1611 120.029 15.2487 120.029 16.6574V25.3888H116.386V17.3674C116.386 16.6462 116.192 16.1052 115.804 15.7446C115.415 15.3839 114.929 15.2036 114.346 15.2036C113.684 15.2036 113.166 15.4121 112.795 15.8291C112.424 16.2404 112.238 16.7842 112.238 17.4604V25.3888H108.698V17.2914C108.698 16.6546 108.512 16.1475 108.141 15.7699C107.775 15.3924 107.292 15.2036 106.692 15.2036C106.287 15.2036 105.921 15.305 105.595 15.5079C105.275 15.7051 105.021 15.9841 104.832 16.3447C104.644 16.6997 104.55 17.1167 104.55 17.5956V25.3888H100.898Z"
        />
        <path
          d="M128.913 25.6423C127.559 25.6423 126.393 25.3718 125.416 24.8309C124.445 24.2843 123.696 23.5123 123.17 22.5149C122.645 21.5119 122.382 20.3258 122.382 18.9565C122.382 17.621 122.645 16.4489 123.17 15.4403C123.696 14.4316 124.436 13.6456 125.39 13.0821C126.35 12.5186 127.476 12.2368 128.767 12.2368C129.636 12.2368 130.444 12.3749 131.193 12.651C131.947 12.9215 132.604 13.33 133.164 13.8766C133.73 14.4232 134.17 15.1106 134.484 15.939C134.799 16.7617 134.956 17.7252 134.956 18.8297V19.8186H123.839V17.5872H131.519C131.519 17.0688 131.404 16.6095 131.176 16.2094C130.947 15.8094 130.63 15.4966 130.224 15.2712C129.824 15.0402 129.359 14.9247 128.827 14.9247C128.273 14.9247 127.782 15.0515 127.353 15.305C126.93 15.553 126.599 15.8883 126.359 16.3109C126.119 16.7279 125.996 17.1927 125.99 17.7055V19.8271C125.99 20.4695 126.11 21.0245 126.35 21.4922C126.596 21.9599 126.942 22.3205 127.387 22.5741C127.833 22.8277 128.362 22.9545 128.973 22.9545C129.379 22.9545 129.75 22.8981 130.087 22.7854C130.424 22.6727 130.713 22.5037 130.953 22.2783C131.193 22.0529 131.376 21.7768 131.502 21.4499L134.879 21.6697C134.707 22.4699 134.356 23.1686 133.824 23.7659C133.299 24.3576 132.619 24.8196 131.784 25.1521C130.956 25.4789 129.999 25.6423 128.913 25.6423Z"
        />
        <path
          d="M144.341 12.4059V15.1106H136.413V12.4059H144.341ZM138.213 9.29539H141.864V21.3992C141.864 21.7317 141.915 21.9909 142.018 22.1768C142.121 22.3572 142.264 22.4839 142.447 22.5572C142.635 22.6305 142.853 22.6671 143.098 22.6671C143.27 22.6671 143.441 22.653 143.613 22.6248C143.784 22.591 143.915 22.5657 144.007 22.5487L144.581 25.2282C144.398 25.2845 144.141 25.3493 143.81 25.4226C143.478 25.5015 143.075 25.5493 142.601 25.5663C141.721 25.6001 140.95 25.4845 140.287 25.2197C139.63 24.9549 139.118 24.5435 138.753 23.9857C138.387 23.4278 138.207 22.7234 138.213 21.8726V9.29539Z"
        />
        <path
          d="M146.72 25.3888V12.4059H150.259V14.6711H150.397C150.637 13.8653 151.039 13.2567 151.605 12.8454C152.171 12.4284 152.822 12.2199 153.559 12.2199C153.742 12.2199 153.939 12.2312 154.151 12.2537C154.362 12.2763 154.548 12.3073 154.708 12.3467V15.5417C154.536 15.491 154.299 15.4459 153.996 15.4065C153.694 15.367 153.416 15.3473 153.165 15.3473C152.628 15.3473 152.148 15.4628 151.725 15.6939C151.308 15.9192 150.977 16.2348 150.731 16.6405C150.491 17.0462 150.371 17.5139 150.371 18.0436V25.3888H146.72Z"
        />
        <path
          d="M156.596 25.3888V12.4059H160.247V25.3888H156.596ZM158.43 10.7323C157.887 10.7323 157.421 10.5548 157.033 10.1998C156.65 9.83916 156.459 9.40808 156.459 8.90657C156.459 8.4107 156.65 7.98526 157.033 7.63026C157.421 7.26963 157.887 7.08931 158.43 7.08931C158.973 7.08931 159.436 7.26963 159.818 7.63026C160.207 7.98526 160.401 8.4107 160.401 8.90657C160.401 9.40808 160.207 9.83916 159.818 10.1998C159.436 10.5548 158.973 10.7323 158.43 10.7323Z"
        />
        <path
          d="M169.12 25.6423C167.772 25.6423 166.612 25.3606 165.64 24.7971C164.675 24.228 163.932 23.4391 163.412 22.4304C162.898 21.4218 162.64 20.261 162.64 18.948C162.64 17.6182 162.9 16.4517 163.42 15.4487C163.946 14.4401 164.692 13.654 165.657 13.0905C166.623 12.5214 167.772 12.2368 169.103 12.2368C170.252 12.2368 171.257 12.4425 172.12 12.8538C172.983 13.2652 173.666 13.8428 174.169 14.5866C174.671 15.3304 174.949 16.2038 175 17.2068H171.554C171.457 16.5588 171.2 16.0376 170.783 15.6431C170.372 15.2431 169.832 15.043 169.163 15.043C168.597 15.043 168.103 15.1952 167.68 15.4994C167.263 15.7981 166.937 16.2348 166.703 16.8096C166.469 17.3843 166.352 18.0802 166.352 18.8973C166.352 19.7256 166.466 20.43 166.695 21.0104C166.929 21.5908 167.257 22.0332 167.68 22.3374C168.103 22.6417 168.597 22.7939 169.163 22.7939C169.58 22.7939 169.954 22.7093 170.286 22.5403C170.623 22.3712 170.9 22.1261 171.117 21.8049C171.34 21.4781 171.486 21.0865 171.554 20.6301H175C174.943 21.6218 174.669 22.4952 174.177 23.2503C173.691 23.9997 173.02 24.5858 172.163 25.0084C171.306 25.431 170.292 25.6423 169.12 25.6423Z"
        />
      </g>
    </svg>
  </div>
</template>

<style scoped>
.app-logo :deep(.logotype) {
  @apply origin-top-left relative;

  transform: scale(1.1);
}

.svg-path {
  transition: fill 0.2s ease-out;
}

.logo-svg:hover .svg-path {
  fill: theme('colors.purple.700');
}

.dark .logo-svg:hover .svg-path {
  fill: theme('colors.yellow.500');
}
</style>
